<template>
  <div class="standard-page">
    <!-- The header buttons -->
    <div class="d-flex justify-end mb-4">
      <!-- The button to delete selected items -->
      <v-btn
        v-if="deletableItems.length"
        depressed
        class="mr-3"
        color="primary"
        :loading="isMakingRequest"
        :disabled="isMakingRequest"
        @click="shouldShowDeleteDialog = true"
      >
        <v-icon left> delete </v-icon>

        Delete
      </v-btn>

      <!-- Button to show the create popup -->
      <v-btn
        depressed
        color="primary"
        :loading="isMakingRequest"
        :disabled="isMakingRequest"
        @click="shouldShowCreateDialog = true"
      >
        <v-icon left> add </v-icon>

        Create Report
      </v-btn>
    </div>

    <v-card class="box-shadow-soft rounded rounded-bl-0 rounded-br-0 px-3 py-6">
      <v-row>
        <!-- Show the selectors -->
        <v-col
          cols="12"
          md="6"
        >
          <v-row>
            <v-col
              v-if="isPartOfTeam"
              cols="12"
              md="4"
            >
              <v-select
                v-model="selectedCreatedBy"
                label="Created By"
                outlined
                dense
                hide-details
                :items="createdByOptions"
              ></v-select>
            </v-col>

            <v-col
              cols="6"
              md="4"
            >
              <platform-selector
                v-model="selectedPlatform"
                :show-youtube="false"
                label="Platform"
                small
                show-all
                outlined
                full-width
              />
            </v-col>

            <v-col
              cols="6"
              md="4"
            >
              <v-select
                v-model="selectedStatus"
                label="Status"
                outlined
                dense
                hide-details
                :items="statusOptions"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-spacer />

        <!-- Show the input -->
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="searchQuery"
            label="Search"
            placeholder="Report title"
            outlined
            dense
            hide-details
            clearable
            append-icon="search"
            @keypress.enter="currentPage = 1"
            @click:append="currentPage = 1"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>

    <!-- Show the results table -->
    <v-data-table
      v-model="selectedItems"
      :headers="tableHeaders"
      :items="tableItems"
      :server-items-length="response.total"
      :page="currentPage"
      :items-per-page="queryPerPage"
      @update:page="currentPage = $event"
      @update:items-per-page="queryPerPage = $event"
      show-select
      selectable-key="isSelectable"
      mobile-breakpoint="100"
      class="box-shadow-soft profile-overview-data-table"
    >
      <!-- For Platform -->
      <template v-slot:item.platform="{ item }">
        <div class="d-flex">
          <v-img height="30" width="30" max-width="30" :src="platformIconMap[item.platform]" />
        </div>
      </template>

      <!-- For Influencer -->
      <template v-slot:item.username="{ item }">
        <profile-chip
          :platform="item.platform"
          :data="item.influencer_preview"
        />
      </template>

      <!-- For Date Range -->
      <template v-slot:item.date_range="{ item }">
        <div>
          <div>
            {{ dayjs(item.start_date).format("ll") }}
          </div>

          <div>
            {{ dayjs(item.end_date).format("ll") }}
          </div>
        </div>
      </template>

      <!-- For posts count -->
      <template v-slot:item.posts_count="{ item }">
        <span>
          {{ item.status === "completed" || item.status === "failed" ? nFormatter(item.posts_count) : "-" }}
        </span>
      </template>

      <!-- For current item progress -->
      <template v-slot:item.status="{ item }">
        <status-chip :status="item.status" />
      </template>

      <!-- For created at -->
      <template v-slot:item.created_at="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              class="d-flex flex-column"
              v-bind="attrs"
              v-on="on"
            >
              <!-- Show the date -->
              <div class="white-space-nowrap">
                {{ formatRelativeDate(item.created_at, "MMMM D, YYYY") }}
              </div>

              <!-- If it's more than 7 days -->
              <div v-if="differenceFromNow(item.created_at) > 7">
                {{ formatRelativeDate(item.created_at, "h:mm A") }}
              </div>
            </div>
          </template>
          <span>
            {{ formatDate(item.created_at) }}
          </span>
        </v-tooltip>
      </template>

      <!-- To show a button for the graph -->
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-end align-center">
          <v-btn
            small
            depressed
            color="primary"
            :disabled="item.status !== 'completed' || item.posts_count === 0"
            @click="$router.push({ name: 'ProfileOverviewReport', params: { uuid: item.uuid } })"
          >
            View
            <v-icon small right> open_in_new </v-icon>
          </v-btn>

          <!-- Show menu options -->
          <v-menu
            transition="slide-y-transition"
            :close-on-content-click="true"
            offset-y
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="ml-3"
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <!-- Show the menu options -->
            <v-list width="140" dense>
              <!-- Show the edit button -->
              <v-list-item
                @click="showEditDialog(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Edit
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the delete button -->
              <v-list-item
                v-if="item.status === 'failed' || item.status === 'completed'"
                @click="showDeleteDialog(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Delete
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>

    <!-- Show the delete confirmation dialog -->
    <v-dialog
      v-model="shouldShowDeleteDialog"
      :persistent="isMakingRequest"
      max-width="400"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <v-icon dark class="mr-3"> delete </v-icon>

          Are you sure?
        </v-card-title>

        <v-card-text class="pt-4">
          You are about to delete {{ deletableItems.length }} {{ deletableItems.length > 1 ? "reports" : "report" }}.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="shouldShowDeleteDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            text
            color="primary"
            :loading="isMakingRequest"
            :disabled="isMakingRequest"
            @click="handleDeleteSubmit"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show the edit dialog -->
    <v-dialog
      v-model="shouldShowEditDialog"
      max-width="400"
    >
      <v-card v-if="selectedItem">
        <v-card-title class="d-flex justify-space-between primary white--text pb-4">
          <div class="d-flex align-center">
            Edit this report
          </div>

          <v-btn
            text
            color="buttonPrimaryText"
            class="primary lighten-1"
            @click="copyReportLink(selectedItem)"
          >
            <v-icon left>
              link
            </v-icon>
            Copy URL
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-6">
          <v-select
            v-model="form.sharingAccess"
            label="Sharing Access"
            :items="sharingAccessOptions"
            :hint="sharingAccessHint"
            class="mt-3"
            outlined
            dense
          ></v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            @click="shouldShowEditDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            @click="handleEditSubmit"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <form-component
      v-model="shouldShowCreateDialog"
      @created="fetchItems"
    />
  </div>
</template>

<script>
// Import helper functions
import messageEvents from "@/helpers/messageEvents"

// Import child components
const StatusChip = () => import(/* webpackChunkName: "status-chip" */ "@/blocks/common/StatusChip")
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ '@/blocks/common/ProfileChip')
const PlatformSelector = () => import(/* webpackChunkName: "platform-selector" */ "@/blocks/common/selectors/PlatformSelector")
const FormComponent = () => import(/* webpackChunkName: "profile-overview-form-component" */ "@/components/profile-overview/Form.vue")

// The subscriptionId for message events
const subscriptionId = Symbol("ProfileOverview/History")

// Export this SFC
export default {
  // Name of this component
  name: "ProfileOverviewHistory",

  // Register the child components
  components: {
    StatusChip,
    ProfileChip,
    PlatformSelector,
    FormComponent
  },

  // Local data variables
  data: () => ({
    // Whether or not we're loading
    isMakingRequest: false,

    // The selected items to be deleted
    selectedItems: [],
    shouldShowDeleteDialog: false,
    shouldShowCreateDialog: false,

    // The selected item to be edited/shown/retried
    selectedItem: null,
    shouldShowEditDialog: false,

    // The result table headers
    tableHeaders: [
      {
        text: "",
        value: "platform",
        sortable: false
      },
      {
        text: "Influencer",
        value: "username",
        sortable: false
      },
      {
        text: "Date Range",
        value: "date_range",
        sortable: false,
        width: 160,
      },
      {
        text: "Posts",
        value: "posts_count",
        sortable: false
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
        align: "center"
      },
      {
        text: "Created At",
        value: "created_at",
        sortable: false,
        align: "end"
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        align: "end"
      }
    ],

    // The edit form
    form: {
      sharingAccess: "self"
    },

    statusOptions: [
      {
        text: "All",
        value: null
      },
      {
        text: "Pending",
        value: "pending"
      },
      {
        text: "Processing",
        value: "processing"
      },
      {
        text: "Completed",
        value: "completed"
      },
      {
        text: "Failed",
        value: "failed"
      }
    ],
  }),

  // Define local computable properties
  computed: {
    /**
     * Get the deletable items
     *
     * @returns {Array}
     */
    deletableItems() {
      return this.selectedItems.filter(item => item.status === "failed" || item.status === "completed")
    },

    /**
     * Get API response from the Vuex store
     *
     * @returns {Object}
     */
    response() {
      return this.$store.getters["profileOverview/response"]
    },

    /**
     * Get the queries from Vuex store
     *
     * @returns {Object}
     */
    query() {
      return this.$store.getters["profileOverview/query"]
    },

    /**
     * Get the createdBy options
     *
     * @returns {Array}
     */
    tableItems() {
      return this.response.data.map((item) => ({
        ...item,
        isSelectable: item.status === "failed" || item.status === "completed"
      }))
    },

    /**
     * Get the current page number in pagination
     */
    currentPage: {
      get() {
        return this.query.page
      },

      set(value) {
        this.$store.dispatch("profileOverview/updateQuery", {
          key: "page",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    /**
     * Get the number of items per page
     */
    queryPerPage: {
      get() {
        return this.query.perPage
      },

      set(value) {
        this.$store.dispatch("profileOverview/updateQuery", {
          key: "perPage",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    /**
     * Get the selected createdBy
     */
    selectedCreatedBy: {
      get() {
        return this.query.createdBy
      },

      set(value) {
        this.$store.dispatch("profileOverview/updateQuery", {
          key: "createdBy",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    /**
     * Get the selected status
     */
    selectedStatus: {
      get() {
        return this.query.status
      },

      set(value) {
        this.$store.dispatch("profileOverview/updateQuery", {
          key: "status",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    /**
     * Get the selected platform
     */
    selectedPlatform: {
      get() {
        return this.query.platform
      },

      set(value) {
        this.$store.dispatch("profileOverview/updateQuery", {
          key: "platform",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    /**
     * Get the search query
     */
    searchQuery: {
      get() {
        return this.query.search
      },

      set(value) {
        // If the value is different
        if (this.query.search === value) return

        this.$store.dispatch("profileOverview/updateQuery", {
          key: "search",
          value
        })
      }
    }
  },

  // Define vuelidate validation rules
  validations: {
    form: {}
  },

  // Method functions to be called from template and script
  methods: {
    /**
     * Fetch the items
     *
     * @returns {void}
     */
    async fetchItems() {
      // If the request is already being made
      if (this.isMakingRequest) return

      // Mark as loading
      this.isMakingRequest = true
      // Dispatch action to fetch items
      await this.$store.dispatch("profileOverview/fetchItems")
      // Mark as not loading
      this.isMakingRequest = false
    },

    /**
     * Show the delete dialog
     *
     * @param {Object} item
     * @returns {void}
     */
    showDeleteDialog(item) {
      this.selectedItems = [item]
      this.shouldShowDeleteDialog = true
    },

    /**
     * Delete the selected items
     *
     * @returns {void}
     */
    async handleDeleteSubmit() {
      // If the request is already being made
      if (this.isMakingRequest) return

      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Go through each selected items
      for (const item of this.deletableItems) {
        // Use vuex store action
        await this.$store.dispatch("profileOverview/deleteItem", {
          id: item.id,
          showToast: false,
          fetchItems: false
        })
      }

      // Hide the loader
      this.$store.dispatch("loaders/remove", loaderId)
      this.isMakingRequest = false

      // Reset the selected items
      this.selectedItems = []

      // Hide the dialog
      this.shouldShowDeleteDialog = false

      // Refresh the items
      this.fetchItems()
    },

    /**
     * Show the edit dialog
     *
     * @param {Object} item
     * @returns {void}
     */
    showEditDialog(item) {
      // Set the form values
      this.form.sharingAccess = item.sharing_access

      this.$v.form.$reset()

      this.selectedItem = item
      this.shouldShowEditDialog = true
    },

    /**
     * Handle the edit action
     *
     * @returns {void}
     */
    async handleEditSubmit() {
      // If the request is already being made
      if (this.isMakingRequest) return

      // Close the dialog
      this.shouldShowEditDialog = false
      this.isMakingRequest = true

      // Use vuex store action
      await this.$store.dispatch("profileOverview/updateItem", { ...this.selectedItem, ...this.form })

      this.isMakingRequest = false
    },

    /**
     * Handle the message event
     *
     * @param {Object} event
     * @returns {void}
     */
    handleMessageEvent(event) {
      this.$store.dispatch("profileOverview/updateLocalItem", event)

      // If it was an error
      if (event.key === "process-profile-overview-failed") {
        // Show a toast
        this.$store.dispatch("toasts/add", { text: event.localData.message || "An error occurred!" })
      }
    },

    /**
     * Copy the report link
     *
     * @return {void}
     */
    copyReportLink(item) {
      // Copy the link value
      const route = this.$router.resolve({ name: "ProfileOverviewReport", params: { uuid: item.uuid } })
      navigator.clipboard.writeText(`${window.location.origin}${route.href}`)

      // Show a toast message
      this.$store.dispatch("toasts/add", { text: "URL copied to clipboard!" })
    },
  },

  /**
   * As soon as the component is rendered
   *
   * @returns {void}
   */
  created() {
    // Fetch the items
    this.fetchItems()

    // Register a subscriber for messageEvents
    messageEvents.register({
      id: subscriptionId,
      module: "profile-overview",
      type: "all",
      key: "all",
      validator: (event) => event.module === "profile-overview",
      callback: this.handleMessageEvent
    })
  },

  /**
   * As soon as the component is destroyed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // Unregister the subscriber for messageEvents
    messageEvents.deregister(subscriptionId)
  }
}
</script>

<style lang="stylus" scoped>
.contain-select-width
  width 12em
</style>

<style lang="stylus">
.profile-overview-data-table thead tr th
  white-space nowrap !important
</style>
